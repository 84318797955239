/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useCallback, useEffect, useRef, useState } from "react";
// import SideNav from "../sideNav/SideNav";
import styles from "../BulkUpload.module.scss";
import uploadAction from "../../../../assets/images/uploadaction.svg";
import iconError from "../../../../assets/images/icon-error.svg";
import errorXlData from "../../../../assets/images/bs-error.svg";
import successXlData from "../../../../assets/images/bs-success.svg";
import readXlsxFile from "read-excel-file";
import Button from "../../button/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../breadCrumb/BreadCrumb";
import { routeConfig } from "../../../constants/routeConfig";
// import Search from "../../../common/components/search/Search";
// import { constants } from "../../constants/constants";
import whiteEye from "../../../../assets/images/whiteEye.svg";
import whiteTrash from "../../../../assets/images/whiteTrash.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import {
  ICategory,
  IEqualTo,
  IOrderingOption,
} from "../../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../../services/useAxios";
import useFetchCountries from "../../../../services/useCountry";
import React from "react";
import Dropdown from "../../dropdown/Dropdown";
import { ToastNotification } from "../../toastNotification/ToastNotification";
import { constants } from "../../../constants/constants";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { errorRowUtils, isAttributePresentInCurrentCategory } from "../utils";
import cancelAction from "../../../../assets/images/chevron-left-white.svg";
import { checkDuplicateProductNameUtils } from "../utils";
import { validExcelUtils } from "../utils";
import { bulkuploadEndpoints } from "../../../constants/constants";
import infoIconBlack from "../../../../pages/buyer/myCart/images/infoIconBlack.svg";
import AttributeCell from "../components/AttributeCell";
import {
  getDynamicMappedKey,
  removeDuplicateVariants,
} from "../../../../pages/buyer/manageCatalogue/ManageCatalogue";
import {
  attributes,
  fileHeaderFields,
} from "../../../../pages/buyer/manageCatalogue/bulkUploadConstants";
import { APIMethods } from "../../../constants/urlConstants";
import { isCurrUserAdmin } from "../../../../utils/clientStorageUtils";
import EmptyProductOption from "../components/EmptyProductOption";
import { getTextWithoutOverflow } from "../../../../utils/functions";
import Popper from "@mui/material/Popper";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { CircularProgress, Paper } from "@mui/material";
import { LightTooltip } from "../components/LightTooltip";
import Table from "./components/Table";

// import fs from 'fs';

type ImagesDataRef = {
  product_name: string;
  url: (string | null)[];
};
const CreateBulkUpload = () => {
  const { countriesList } = useFetchCountries();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imagesDataRef = useRef<ImagesDataRef[]>([]);
  const needToGetImagesURI = useRef<boolean>(true);
  const [loggedInUser] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );
  // const [userType, setUserType] = useState("supplier");
  const tableDataFromParent = location?.state?.tableData;
  const duplicateProductOptionsIndex =
    location?.state?.duplicateProductOptionsIndex;
  const headerKeys = location?.state?.headerKeys;
  const [variantsData, setVariantsData] = useState([]);
  const [tableData, setTableData] = useState(tableDataFromParent);
  const [xlFileName, setXlFileName] = useState(location?.state?.name ?? "");
  const [errorCounts, setErrorCounts] = useState<any>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [measuringUnits, setMeasuringUnits] = useState<IOrderingOption[]>([]);
  const [brandName, setBrandName] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState(false);
  // const [customerBusinessId, setCustomerBusinessId] = useState<string>("");
  const [businessId, setBusinessId] = useState<string>("");
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [fileImportFail, setFileImportFail] = useState(false);
  // const [matchingUnit, setMatchingUnit] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { supplierId } = useParams();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popperRef = useRef<any>();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const getProductImageURI = useAxios({
    axiosParams: {
      url: `store/miniobucket/uploadlink`,
    },
    method: APIMethods.post,
  });

  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      // setCustomerBusinessId(loggedInUser?.business_details?.[0]?.id);
      setBusinessId(loggedInUser?.business_details?.[0]?.business_setup_id);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    if (popperRef.current) {
      setAnchorEl(popperRef.current);
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, 5000);
  }, []);

  const breadcrumbItems = [
    {
      label: "Manage Catalogue",
      link: isAdmin
        ? supplierId
          ? routeConfig.uploadSupplierProductsFromAdmin.replace(
              ":supplierId",
              supplierId
            )
          : routeConfig.adminManageCatalogue
        : routeConfig.productListingCatalogue,
    },
    { label: "Bulk product upload" },
  ];

  useEffect(() => {
    if (tableDataFromParent?.length) {
      let header = { ...headerKeys };
      let Data = [...tableDataFromParent];
      Data.unshift(header);
      let newDataReq: any = [];
      Data.forEach((item, index) => {
        // console.log(Object.values(item), 'III');
        if (index === 0) {
          newDataReq.push(Object.values(item));
        } else {
          let valuesArray: any = [];
          Object.values(item).forEach((vItem: any) => {
            valuesArray.push(vItem.value);
          });
          newDataReq.push(valuesArray);
        }
      });
      // dispatch(updateIsLoading(true));
      if (
        countriesList?.length > 0 &&
        measuringUnits?.length > 0 &&
        categories?.length > 0
      ) {
        const arrayOfObjects = createArrayOfObjects(newDataReq, false).map(
          (el: any, i: number) => {
            return {
              ...el,
              duplicateProductOptionsIndex: duplicateProductOptionsIndex[i],
            };
          }
        );
        setTableData(arrayOfObjects);
        // dispatch(updateIsLoading(false));
      }
    }
  }, [countriesList, measuringUnits, categories]);

  // get measuring units api call starts
  const measuringUnitsApi = useAxios({
    axiosParams: {
      url: "store/units",
    },
    method: "GET",
  });

  const getMeasuringUnits = useCallback(async () => {
    const response: any = await measuringUnitsApi?.fetchData();
    if (response) {
      const updatedMeasuringUnits = response?.data?.product_units?.map(
        (unit: IOrderingOption) => ({
          ...unit,
          value: unit.name,
          sub_units: unit?.sub_units?.map((subUnit: IEqualTo) => ({
            ...subUnit,
            value: subUnit?.name,
          })),
        })
      );
      setMeasuringUnits(updatedMeasuringUnits);
    }
  }, [measuringUnitsApi]);

  useEffect(() => {
    getMeasuringUnits();
    getAllCategoriesList();
  }, []);

  // get categories api call starts
  const getCategories = useAxios({
    axiosParams: {
      url: "store/categories",
    },
    method: "GET",
  });
  // console.log(subCategories, "subCategories");
  const getAllCategoriesList = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getCategories?.fetchData();
    if (response) {
      const updatedCategoriesData = response?.data?.categories?.map(
        (category: ICategory) => ({
          ...category,
          value: category.name,
          subcategories: category?.sub_categories?.map(
            (subCategory: ICategory) => ({
              ...subCategory,
              value: subCategory?.name,
              sub_sub_categories: subCategory?.sub_sub_categories?.map(
                (subSubCategory: ICategory) => ({
                  ...subSubCategory,
                  value: subSubCategory?.name,
                })
              ),
            })
          ),
        })
      );
      const finalCategoreis = updatedCategoriesData?.filter(
        (item: ICategory) => item?.name !== "Offline Product Category"
      );
      setCategories(finalCategoreis);
      dispatch(updateIsLoading(false));
    }
  }, [getCategories]);

  const getErrorStateForRow = (rowData: any) => {
    // console.log(rowData, "ROWDATAAAAAA");
    let errCount_1 = 0;
    let errCount_2 = 0;
    let errCount_3 = 0;
    let errCount_4 = 0;
    let errCount_5 = 0;
    // varCheck(rowData);
    for (const property in rowData) {
      //Should be romoved once the images upload completed
      if (property?.includes("image")) {
        return false;
      } else if (
        property === "title" ||
        property === "tags" ||
        property === "category" ||
        property === "sub_category" ||
        property === "sub_sub_category" ||
        property === "country"
        //  ||
        // property?.includes("price") ||
        // property?.includes("min_quantity") ||
        // property?.includes("ordering_option_label") ||
        // property?.includes("ordering_option_value") ||
        // property?.includes("secondary_ordering_option_label") ||
        // property?.includes("secondary_ordering_option_value") ||
        // property?.includes("currency_type")
      ) {
        if (!rowData[property].value || rowData[property].value === null) {
          // console.log(rowData[property], "ERRR PROPERTY", property);
          return true;
        }
      } else if (property?.includes("_1") && !property?.includes("offer")) {
        if (!rowData[property].value || rowData[property].value === null) {
          errCount_1 = errCount_1 + 1;
          // console.log(errCount_1, 'errCount_1');
        }
      } else if (property?.includes("_2") && !property?.includes("offer")) {
        if (!rowData[property].value || rowData[property].value === null) {
          errCount_2 = errCount_2 + 1;
          // console.log(errCount_2, 'errCount_2');
        }
      } else if (property?.includes("_3") && !property?.includes("offer")) {
        if (!rowData[property].value || rowData[property].value === null) {
          errCount_3 = errCount_3 + 1;
          // console.log(errCount_3, 'errCount_3');
        }
      } else if (property?.includes("_4") && !property?.includes("offer")) {
        if (!rowData[property].value || rowData[property].value === null) {
          errCount_4 = errCount_4 + 1;
          // console.log(errCount_4, 'errCount_4');
        }
      } else if (property?.includes("_5") && !property?.includes("offer")) {
        if (!rowData[property].value || rowData[property].value === null) {
          errCount_5 = errCount_5 + 1;
          // console.log(errCount_5, 'errCount_5');
        }
      }
      // if (property !== "media" && rowData[property].errorState) {
      //   return true;
      // }
    }
    // return false;
    if (
      (errCount_1 > 0 && errCount_1 < 7) ||
      (errCount_2 > 0 && errCount_2 < 7) ||
      (errCount_3 > 0 && errCount_3 < 7) ||
      (errCount_4 > 0 && errCount_4 < 7) ||
      (errCount_5 > 0 && errCount_5 < 7)
    ) {
      return true;
    }
  };
  // console.log(measuringUnits, "measuringUnits");

  const getRowsWithErrorsCount = (data: any[]) => {
    return (
      data &&
      data.reduce((count, rowData, index) => {
        if (errorRowUtils(rowData, index)) {
          return count + 1;
        }
        return count;
      }, 0)
    );
  };

  useEffect(() => {
    const updatedTableData =
      tableData &&
      tableData.map((rowData: any) => {
        const updatedRowData = { ...rowData };
        for (const property in updatedRowData) {
          const field =
            typeof updatedRowData[property] === "boolean"
              ? {}
              : updatedRowData[property];
          field.errorState = !field.value;
        }
        return updatedRowData;
      });
    setTableData(updatedTableData);
  }, []);

  const changeEditStatus = async (
    field: string,
    index: number,
    value: any,
    typeEdit?: boolean
  ) => {
    const updatedTableData = [...tableData];
    const fieldData = updatedTableData[index][field];
    // console.log(updatedTableData, '---UPDATEDDDDDD');

    const countMatch = field.match(/\d+/);
    const count = countMatch ? parseInt(countMatch[0]) - 1 : null;
    if (
      field.includes("price") ||
      // field.includes("currency_type") ||
      field.includes("min_quantity") ||
      field.includes("ordering_option_label") ||
      field.includes("ordering_option_value") ||
      field.includes("secondary_ordering_option_label") ||
      field.includes("secondary_ordering_option_value") ||
      field.includes("offer")
    ) {
      if (count !== null) {
        const updatedVariantsData: any = [...variantsData];
        if (
          field?.startsWith("ordering_option") &&
          field?.includes("label_") &&
          value?.length
        ) {
          const matchingUnitData: any = measuringUnits.find(
            (item) => value?.toLowerCase() === item?.name?.toLowerCase()
          );
          if (matchingUnitData) {
            // matchingUnit = matchingUnitData;
            updatedVariantsData[count][field] = {
              ...updatedVariantsData[count][field],
              unit: matchingUnitData,
              value:
                typeEdit === false
                  ? updatedVariantsData[count][field].value
                  : matchingUnitData.name,
              subUnits: matchingUnitData?.sub_units,
              editMode: false,
              errorState: !value,
            };
            updatedTableData[index][field] = {
              unit: matchingUnitData,
              value:
                typeEdit === false
                  ? updatedVariantsData[count][field].value
                  : matchingUnitData.name,
              subUnits: matchingUnitData?.sub_units,
              editMode: false,
              errorState: !value,
            };
            if (!field.startsWith("secondary_ordering_option_label")) {
              updatedTableData[index][
                `secondary_ordering_option_label_${count + 1}`
              ] = {
                value: "",
                editMode: false,
                errorState: true,
              };
            }
          }
        } else {
          // console.log(222222)
          updatedVariantsData[count][field] = {
            ...updatedVariantsData[count][field],
            value:
              typeEdit === false
                ? updatedVariantsData[count][field].value
                : value,
          };
        }
        setVariantsData(updatedVariantsData);
      }
    }

    if (field === "media") {
      // console.log(value, 'VVVVV', updatedTableData[index]?.[field]?.['value']?.length > 0);
      if (Array.isArray(value)) {
        let updatedImages: any = [];
        value?.map(async (file: any) => {
          // if(typeof file != 'string')
          if (!file?.image?.length) {
            updatedImages?.push({
              image: URL.createObjectURL(file),
              fileName: file?.name,
            });
            formdata.append(`multiplefiles`, file);
            const response: any = await getImagesUrl?.fetchData();
            // console.log(response, 'responseMEDIAAAA');
            if (response && response?.status === "SUCCESS") {
              const updatedImagesUrl = response?.data?.map((image: string) => ({
                id: null,
                url: image,
              }));
              updatedTableData[index]["media"]["updatedImagesUrl"].push(
                updatedImagesUrl
              );
            }
          }
        });
        updatedTableData[index][field]["value"] = [
          ...updatedTableData[index][field]?.["value"],
          ...updatedImages,
        ];
        // updatedTableData[index][field]?.["value"].push(updatedImages[0]);
        updatedTableData[index][field]["editMode"] = false;
        updatedTableData[index][field]["errorState"] = false;
      }
    } else if (field === "image_1") {
      updatedTableData[index]["image_1"] = {};
      // updatedTableData[index]['image_1']['value'] = value[0];
      updatedTableData[index]["image_1"]["value"] = URL.createObjectURL(
        value?.find((item: any) => !item?.image?.length)
      );
      updatedTableData[index][field]["editMode"] = false;
      updatedTableData[index][field]["errorState"] = false;
      updatedTableData[index][field]["fileName"] = value?.[0]?.name;
      formdata.append(`multiplefiles`, value[0]);
      // console.log(formdata, 'FFFF', );
      const response: any = await getImagesUrl?.fetchData();
      // console.log(response, 'response');
      if (response && response?.status === "SUCCESS") {
        const updatedImagesUrl = response?.data?.map((image: string) => ({
          id: null,
          url: image,
        }));
        updatedTableData[index]["image_1"]["updatedImagesUrl"] =
          updatedImagesUrl;
      }
    } else if (Array.isArray(fieldData?.value)) {
      if (typeof typeEdit === "boolean") {
        fieldData.editMode = typeEdit;
      } else {
        fieldData.value = value?.map((item: string) => item.trim());
      }
    } else {
      fieldData.editMode = typeof typeEdit === "boolean" ? typeEdit : true;
      if (typeof typeEdit !== "boolean" && typeof value === "string") {
        fieldData.value = value;
      }
    }

    if (fieldData) {
      fieldData.errorState = !fieldData.value;
    }

    if (field == "category") {
      const categorySelected = categories?.find(
        (item: any) => item.name == value
      );
      if (categorySelected) {
        // console.log(categorySelected, '---CAT SELECTED');
        updatedTableData[index][field] = { ...categorySelected };
        updatedTableData[index][field]["id"] = categorySelected?.id;
        updatedTableData[index][field]["subcategories"] =
          categorySelected.subcategories;

        // updatedTableData[index]['sub_category'].value = categorySelected?.subcategories?.[0]?.name;
        updatedTableData[index]["sub_category"].value = "";
        updatedTableData[index]["sub_category"].editMode = true;
        updatedTableData[index]["sub_category"].errorState = false;

        // updatedTableData[index]["sub_category"]["subCategory"] =
        //   categorySelected?.subcategories?.[0];
        // updatedTableData[index]["sub_category"]["subSubCategories"] =
        //   categorySelected?.subcategories?.[0]?.sub_sub_categories;
        updatedTableData[index]["sub_sub_category"].value = "";
        updatedTableData[index]["sub_sub_category"].editMode = true;
        updatedTableData[index]["sub_sub_category"].errorState = false;
      }
    } else if (field == "sub_category") {
      const subCategorySelected = updatedTableData[index]["category"][
        "subcategories"
      ]?.find((item: any) => item.name == value);
      if (subCategorySelected) {
        // console.log(subCategorySelected, 'SUBBBB')
        updatedTableData[index][field] = { ...subCategorySelected };
        updatedTableData[index][field]["id"] = subCategorySelected?.id;
        updatedTableData[index][field].value = subCategorySelected?.name;
        updatedTableData[index]["sub_category"].editMode = false;
        updatedTableData[index]["sub_category"].errorState = false;

        // updatedTableData[index]['sub_sub_category'].value = '';
        // updatedTableData[index]["sub_sub_category"] =
        //   subCategorySelected?.sub_sub_categories?.[0];
        updatedTableData[index]["sub_sub_category"].value = "";
        // updatedTableData[index]['sub_sub_category'].value = subCategorySelected?.sub_sub_categories?.[0]?.name;
        updatedTableData[index]["sub_sub_category"].editMode = true;
        updatedTableData[index]["sub_sub_category"].errorState = false;

        updatedTableData[index][field]["subSubCategories"] =
          subCategorySelected?.sub_sub_categories;
        // updatedTableData[index]['sub_sub_category']['subSubCategory'] = {};
        // updatedTableData[index]['sub_sub_category']['value'] = "";
      }
    } else if (field == "sub_sub_category") {
      const subSubCategorySelected = updatedTableData[index]["sub_category"][
        "subSubCategories"
      ]?.find((item: any) => item.name == value);
      // console.log(subSubCategorySelected, 'SUBBBB-SUBBBB')
      if (subSubCategorySelected) {
        updatedTableData[index][field] = { ...subSubCategorySelected };
        updatedTableData[index][field]["id"] = subSubCategorySelected?.id;
        updatedTableData[index][field].value = subSubCategorySelected?.name;
        updatedTableData[index][field].editMode = false;
        updatedTableData[index][field].errorState = false;
      }
    } else if (field.includes("offer") && typeof value != "boolean") {
      updatedTableData[index][field].value =
        value <= 99 && value >= 0 ? value : "";
    } else if (field === "country" && value?.length) {
      let countryObj = countriesList?.find(
        (item) => item?.name?.toLowerCase() === value?.toLowerCase()
      );
      // console.log(countryObj, 'country1111', countriesList);
      if (countryObj) {
        updatedTableData[index][field] = countryObj;
        updatedTableData[index][field]["value"] = countryObj?.name;
      }
    }

    let errCount = getRowsWithErrorsCount(updatedTableData);
    setTableData(updatedTableData);
    setErrorCounts(errCount);
  };

  const logoUpload = ({ event, index }: any) => {
    // console.log('UPLOAD IMAGE', event, index);
    const updatedTableData = [...tableData];
    updatedTableData[index].data.logo.value = URL.createObjectURL(
      event.target.files[0]
    );
    setTableData(updatedTableData);
  };
  const cancel = () => {
    setTableData([]);
    isAdmin
      ? supplierId
        ? navigate(
            routeConfig.supplierApprovalForm.replace(":businessId", supplierId)
          )
        : navigate(routeConfig.adminProductAddCards)
      : navigate(routeConfig.manageSupplierCatalogue);
  };

  // get images url api call starts
  let formdata = new FormData();
  // console.log(formdata, 'FORMDATA')
  const getImagesUrl = useAxios({
    axiosParams: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: "store/miniobucket/uploadmultiplefile",
      data: formdata,
    },
    method: "POST",
  });

  // get the brand name or create the brand name api call starts not using now
  const getBrand = useAxios({
    axiosParams: {
      url: "store/brands",
      data: {
        name: brandName,
      },
    },
    method: "POST",
  });

  const finalTags = (tagsData: Array<String>) => {
    // console.log(tagsData, '---tags');
    let tags: Array<String> = [];
    if (tagsData?.[0]?.length > 0) {
      tagsData?.forEach((item, index) => {
        let obj: any = { value: item };
        tags.push(obj);
      });
    } else {
      return [];
    }
    return tags;
  };

  const getImageLinks = (rowData: any, index?: any) => {
    // console.log(rowData, '---ROWWWW');
    let imagesArray: any = [
      {
        url: rowData?.image_1?.updatedImagesUrl?.[0]?.url,
        metadata: {
          default_image: true,
        },
      },
    ];

    if (rowData?.imgUrlLoaded) {
      rowData?.media?.updatedImagesUrl?.forEach((item: any) => {
        imagesArray?.push({
          url: item?.[0]?.url,
          metadata: {
            default_image: false,
          },
        });
      });
    } else {
      const isDefaultImgExist = imagesDataRef.current[index]?.url[0];
      if (isDefaultImgExist) {
        imagesDataRef.current[index]?.url
          ?.slice(1)
          .filter(Boolean)
          .forEach((img: any) => {
            if (img) {
              imagesArray?.push({
                url: img,
                metadata: {
                  default_image: false,
                },
              });
            }
          });
      }
    }

    // console.log(imagesArray, '=====imagesArray');
    return imagesArray;
  };

  //Validate final variant before adding it to payload
  const validatevariant = (vData: any) => {
    // console.log(JSON.stringify(Object.values(vData)), 'VVVVVV2222');
    if (JSON.stringify(Object.values(vData))?.includes("null")) {
      return false;
    } else if (JSON.stringify(Object.values(vData))?.includes("[{}]")) {
      return false;
    }
    return true;
  };

  const transformVariantPayload = (rowData: any) => {
    let numOfvariants = rowData?.varaints?.length;
    let variantsDataFinal = [];
    for (let i = 1; i <= numOfvariants; i++) {
      const secondaryOptionId = rowData?.[
        `ordering_option_label_${i}`
      ]?.subUnits?.find(
        (el: any) =>
          el?.name === rowData[`secondary_ordering_option_label_${i}`]?.value
      );
      let variant = {
        title: rowData?.title?.value,
        unit_price: rowData[`unit_price_${i}`]?.value,
        min_quantity: rowData[`min_quantity_${i}`]?.value,
        metadata: {
          ordering_option_value: rowData[`ordering_option_value_${i}`]?.value
            ? Number(rowData[`ordering_option_value_${i}`]?.value)
            : null,
          ordering_option_id: rowData[`ordering_option_label_${i}`]?.unit?.id,
          ordering_option_label: rowData[`ordering_option_label_${i}`]?.value,
          secondary_ordering_option_value: rowData[
            `secondary_ordering_option_value_${i}`
          ]?.value
            ? Number(rowData[`secondary_ordering_option_value_${i}`]?.value)
            : null,
          secondary_ordering_option_id: secondaryOptionId?.sub_unit_id,
          secondary_ordering_option_label:
            rowData[`secondary_ordering_option_label_${i}`]?.value,
          offer: Number(rowData[`offer_${i}`]?.value),
        },
      };
      if (validatevariant(variant)) {
        variantsDataFinal.push(variant);
      }
    }
    return variantsDataFinal;
  };

  function getCurrentProductAttributes(product: any) {
    const obj: any = {};
    for (let attribute in product) {
      if (attributes.includes(attribute)) {
        if (!isAttributePresentInCurrentCategory(attribute, product)) {
          obj[attribute] = product[attribute]?.value;
        }
      }
    }
    return obj;
  }
  async function fetchProductImages(reUploaded: any) {
    async function fetchFewProductsImages(start = 0, end = 20) {
      const filteredImagesData = imagesDataRef.current
        .map((el: any) => {
          return { ...el, url: el.url.filter(Boolean) };
        })
        .slice(start, end);
      if (!filteredImagesData || filteredImagesData?.length <= 0) {
        needToGetImagesURI.current = false;
        return;
      }
      const response: any = await getProductImageURI.fetchData({
        axiosParams: {
          data: { uploaded_links: filteredImagesData },
        },
      });

      const isBase64Data = (url: string) => url?.startsWith("data:");
      if (response.data) {
        setTableData((prev: any) => {
          const currentTableData = [...prev].slice(start, end);
          currentTableData.forEach((product: any, index) => {
            const isDefaultImgExist =
              imagesDataRef.current[start + index]?.url[0];

            product.imgUrlLoaded = true;
            if (isDefaultImgExist) {
              if (
                response.data[index]?.url[0] &&
                isBase64Data(response.data[index]?.url[0])
              ) {
                product.image_1.value = response.data[index]?.url[0];
                product.image_1.updatedImagesUrl = [
                  {
                    url: imagesDataRef.current[start + index]?.url[0],
                  },
                ];
              } else if (response.data[index]?.url[0]) {
                product.image_1.value = response.data[index]?.url[0];
                product.image_1.updatedImagesUrl = [
                  {
                    url: response.data[index]?.url[0],
                  },
                ];
              }
            }
            let sliceStartVal = isDefaultImgExist ? 1 : 0;
            console.log(product, "product");
            product.media.updatedImagesUrl = response.data[index]?.url
              ?.slice(sliceStartVal)
              .map((el: any, i: number) => {
                if (isBase64Data(el)) {
                  return [
                    {
                      url: imagesDataRef.current[start + index]?.url
                        .filter(Boolean)
                        ?.slice(sliceStartVal)[i],
                      id: null,
                    },
                  ];
                } else {
                  return [
                    {
                      url: el,
                      id: null,
                    },
                  ];
                }
              });
            product.media.value = response.data[index]?.url
              ?.slice(sliceStartVal)
              .map((el: any) => {
                return {
                  image: el,
                };
              });
          });

          const copyTableData = [...prev];
          copyTableData.splice(
            start,
            currentTableData.length,
            ...currentTableData
          );

          if (needToGetImagesURI.current || reUploaded) {
            return copyTableData;
          } else {
            return prev;
          }
        });
      }
      if (needToGetImagesURI.current || reUploaded) {
        fetchFewProductsImages(start + 20, end + 20);
      }
    }
    if (needToGetImagesURI.current || reUploaded) {
      fetchFewProductsImages();
    }
  }

  const uploadProducts = async (bulkProductsData: any) => {
    // console.log(bulkProductsData, 'BBBBB');
    needToGetImagesURI.current = false;
    const response: any = await getBrand?.fetchData({
      axiosParams: {
        url:
          isAdmin && !supplierId
            ? bulkuploadEndpoints?.admin
            : bulkuploadEndpoints?.supplier?.replace(
                ":supplier_id",
                supplierId ? supplierId : businessId
              ) + `?isAdmin=${isAdmin}`,
        data: bulkProductsData,
      },
      method: "POST",
    });
    if (response && response?.status === "SUCCESS") {
      // dispatch(resetWholeForm());
      setToastData({
        message: "Created products successfully",
        status: "Success",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
      dispatch(updateIsLoading(false));
      setTimeout(() => {
        navigate(
          isAdmin
            ? supplierId
              ? routeConfig.supplierApprovalForm.replace(
                  ":businessId",
                  supplierId
                )
              : routeConfig.adminManageCatalogue
            : routeConfig.productListingCatalogue
        );
      }, 1000);
    } else {
      setToastData({
        message: response?.response?.data?.error_msg?.includes(
          "Cannot read properties of undefined (reading 'id')"
        )
          ? "Please enter an existing watermelon product code"
          : response?.response?.data?.error_msg,
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
      dispatch(updateIsLoading(false));
    }
  };

  const createFinalProducts = async () => {
    // console.log(tableData, "FINAL DATAAAA", loggedInUser);
    let final: any = [];
    for (const [index, item] of tableData.entries()) {
      // let brandId = "";
      // const response: any = await getBrand?.fetchData({
      //   axiosParams: {
      //     url: "store/brands",
      //     data: {
      //       name: item?.brand?.value,
      //     },
      //   },
      //   method: "POST",
      // });
      // console.log(response, 'RRRRRRRRRRRRRR');
      // if (response?.data && response?.data[0]) {
      // console.log(response?.data[0]?.id, 'IDDDDDDDDDDDDD')
      // brandId = response?.data[0]?.id;
      let obj = {
        title: item?.title?.value,
        is_vat_included: item?.is_vat_included?.value === "true",
        ...((!isAdmin || supplierId) && {
          master_watermelon_product_code: item?.master_watermelon_product_code
            ?.value?.length
            ? item?.master_watermelon_product_code?.value
            : "",
        }),
        // handle: `${item?.title?.value}${loggedInUser?.id}`,
        // ware_house_id:
        //   loggedInUser &&
        //   loggedInUser?.business_details &&
        //   loggedInUser?.business_details?.[0]?.business_setup_id,
        description: item?.description?.value,
        // status: "draft",
        variants: transformVariantPayload(item),
        brand: item?.brand?.value
          ? typeof item?.brand?.value === "number"
            ? String(item?.brand?.value)
            : item?.brand?.value
          : undefined,
        metadata: {
          certifications:
            item?.certifications?.value
              ?.split(",")
              .map((el: any) => el?.trim().toUpperCase()) || [],
          product_details:
            item?.specifications?.value
              ?.split(",")
              .map((el: any) => el?.trim().toUpperCase()) || [],
          attributes: getCurrentProductAttributes(item),
        },
        tags: finalTags(item?.tags?.value?.split(",")),
        category_id: item?.category?.id
          ? item?.category?.id
          : item?.category?.category?.id,
        sub_category_id: item?.sub_category?.subCategory
          ? item?.sub_category?.subCategory?.id
          : item?.sub_category?.id,
        sub_sub_category_id: item?.sub_sub_category?.subSubCategory
          ? item?.sub_sub_category?.subSubCategory?.id
          : item?.sub_sub_category?.id,
        countries: {
          iso_2: item?.country?.country
            ? item?.country?.country?.iso_2
            : item?.country?.iso_2,
        },
        hs_code: String(item?.hs_code?.value || ""),
        product_code: String(item?.supplier_product_code?.value || ""),
        images: getImageLinks(item, index),
      };
      final.push(obj);
    }
    if (tableData?.length === final?.length) {
      uploadProducts(final);
    }
  };

  const submitData = () => {
    dispatch(updateIsLoading(true));
    createFinalProducts();
  };
  function createArrayOfObjects(dataArray: any, reUploaded: Boolean) {
    const keys = dataArray[0];
    const arrayOfObjects = [];
    const variantsArr: any = [];
    let matchingUnit = null;
    let longData: any = Object.values(dataArray[0]);
    for (let i = 0; i < longData?.length; i++) {
      if (longData?.[i]?.includes("unit_price")) {
        variantsArr.push({});
      }
    }
    imagesDataRef.current = [];
    // console.log(dataArray, 'DATA ARRAY--------------', countriesList, measuringUnits);
    for (let i = 1; i < dataArray.length; i++) {
      const values = dataArray[i];
      // console.log(values, '=================VALUES');
      const obj: any = {
        imgUrlLoaded: true,
      };
      let categoryMatched = false;
      let countryMatched = false;
      let subCategoryMatched = false;
      let subSubCategoryMatched = false;
      let allAttributesList: any = [];
      let unitDataFound = false;
      const productImages: { product_name: string; url: (string | null)[] } = {
        product_name: "",
        url: [],
      };
      // console.log(keys, 'KEYYSSS');
      const mediaArr = [];
      for (let j = 0; j < keys.length; j++) {
        // Reset flags at the beginning of each iteration
        let unitDataFound = false;
        let subUnitDataFound = false;
        // console.log(keys[j], '+++++KEYYYY');
        const fieldKey = keys[j].toLowerCase().replace(/\s+/g, "");
        const countMatch = fieldKey.match(/\d+/);
        // console.log('000000000000000', fieldKey);

        if (
          fieldKey.includes("price") ||
          // fieldKey.includes("currency_type") ||
          fieldKey.includes("min_quantity") ||
          fieldKey.includes("ordering_option_label") ||
          fieldKey.includes("ordering_option_value") ||
          fieldKey.includes("secondary_ordering_option_label") ||
          fieldKey.includes("secondary_ordering_option_value") ||
          fieldKey.includes("offer")
        ) {
          const count = countMatch ? countMatch[0] : null;

          if (count) {
            const index = parseInt(count) - 1;

            if (
              fieldKey.includes("ordering_option_label") &&
              !fieldKey.startsWith("secondary")
            ) {
              // console.log('111111', values[j], measuringUnits)
              const matchingUnitData: any = measuringUnits.find(
                (item) =>
                  String(values[j])?.toLowerCase() === item?.name?.toLowerCase()
              );
              if (matchingUnitData) {
                matchingUnit = matchingUnitData;
                variantsArr[index][fieldKey] = {
                  unit: matchingUnitData,
                  value: matchingUnitData.name,
                  subUnits: matchingUnitData?.sub_units,
                  editMode: false,
                  errorState: !values[j],
                };
                obj[fieldKey] = {
                  unit: matchingUnitData,
                  value: matchingUnitData.name,
                  subUnits: matchingUnitData?.sub_units,
                  editMode: false,
                  errorState: !values[j],
                };

                // console.log(22222, matchingUnitData, obj[fieldKey], variantsArr);
                // console.log(variantsArr[index][fieldKey], '----variantsArr[index][fieldKey]', fieldKey);
                unitDataFound = true;
              } else {
                obj[fieldKey] = {
                  value: "",
                  editMode: false,
                  errorState: !values[j],
                };
                // continue;
              }
            } else if (fieldKey.includes("secondary_ordering_option_label")) {
              // console.log(2222, matchingUnit?.sub_units);
              const isInSubUnits = matchingUnit?.sub_units?.find(
                (item: any) =>
                  values[j]?.toLowerCase() === item?.name?.toLowerCase()
              );

              if (isInSubUnits) {
                variantsArr[index][fieldKey] = {
                  subUnit: isInSubUnits,
                  value: isInSubUnits?.name,
                  editMode: false,
                  errorState: !values[j],
                  unit: isInSubUnits,
                };
                obj[fieldKey] = {
                  value: isInSubUnits?.name,
                  editMode: false,
                  errorState: !values[j],
                  unit: isInSubUnits,
                };
                subUnitDataFound = true;
              } else {
                variantsArr[index][fieldKey] = {
                  value: "",
                  editMode: false,
                  errorState: !values[j],
                };
                obj[fieldKey] = {
                  value: "",
                  editMode: false,
                  errorState: !values[j],
                };
                // continue;
              }
            } else if (fieldKey?.includes("offer")) {
              variantsArr[index][fieldKey] = {
                value:
                  values[j] || values[j] === 0
                    ? String(values[j] <= 99 ? values[j] : values[j] ? 0 : "")
                    : "",
                editMode: false,
                errorState: !values[j],
              };
              obj[fieldKey] = {
                value:
                  values[j] || values[j] === 0
                    ? String(values[j] <= 99 ? values[j] : values[j] ? 0 : "")
                    : "",
                editMode: false,
                errorState: !values[j],
              };
            } else if (
              fieldKey?.includes("min_quantity") ||
              fieldKey?.includes("price") ||
              fieldKey.includes("ordering_option_value")
            ) {
              variantsArr[index][fieldKey] = {
                value: values[j] > 0 ? values[j] : "",
                editMode: false,
                errorState: !values[j],
              };
              obj[fieldKey] = {
                value: values[j] > 0 ? values[j] : "",
                editMode: false,
                errorState: !values[j],
              };
            } else {
              variantsArr[index][fieldKey] = {
                value: values[j],
                editMode: false,
                errorState: !values[j],
              };
              obj[fieldKey] = {
                value: values[j],
                editMode: false,
                errorState: !values[j],
              };
            }
          }
        } else if (
          fieldKey === "country" &&
          countriesList &&
          countriesList.length > 0
        ) {
          for (const item of countriesList) {
            if (
              values[j]?.toLowerCase() ===
              (item?.name?.toLowerCase() ||
                item?.iso_3.toLowerCase() ||
                item?.iso_2?.toLowerCase())
            ) {
              // console.log(values[j]?.toLowerCase(), countriesList, '-------CCCCCCCCCCCCCCCCCCCC');
              obj[fieldKey] = {
                country: item,
                flag: item?.flag,
                value: item?.name,
                editMode: false,
                errorState: !values[j],
              };
              countryMatched = true;
              break;
            }
          }
          if (!countryMatched) {
            obj[fieldKey] = {
              value: "",
              editMode: false,
              errorState: !values[j],
            };
          }
        } else if (
          fieldKey === "category" &&
          categories &&
          categories?.length > 0
        ) {
          for (const item of categories) {
            // console.log(categories, '====CATEGORIES')
            if (values[j]?.toLowerCase() === item?.name?.toLowerCase()) {
              setSubCategories(item?.subcategories as any);
              // console.log(item, 'ITEM______')
              obj[fieldKey] = {
                category: item,
                subCategories: item?.sub_categories,
                subcategories: item?.subcategories,
                value: item?.name,
                id: item?.id,
                editMode: false,
                errorState: !values[j],
              };
              allAttributesList = [
                ...allAttributesList,
                ...(item.category_attributes || []),
              ];

              categoryMatched = true;
              break;
            }
          }
          if (!categoryMatched) {
            obj[fieldKey] = {
              value: "",
              editMode: false,
              errorState: !values[j],
            };
          }
        } else if (fieldKey === "sub_category" && !categoryMatched) {
          obj["sub_category"] = {
            value: "",
            editMode: false,
            errorState: !values[j],
          };
        } else if (
          fieldKey === "sub_category" &&
          obj["category"]?.subCategories &&
          obj["category"]?.subCategories?.length > 0
        ) {
          for (const item of obj["category"]?.subCategories) {
            if (values[j]?.toLowerCase() === item?.name?.toLowerCase()) {
              obj[fieldKey] = {
                subCategory: item,
                subSubCategories: item?.sub_sub_categories,
                value: item?.name,
                editMode: false,
                errorState: !values[j],
              };
              allAttributesList.concat(item.sub_category_attributes);
              subCategoryMatched = true;
              break;
            }
          }
          if (!subCategoryMatched) {
            obj[fieldKey] = {
              value: "",
              editMode: false,
              errorState: !values[j],
            };
          }
        } else if (fieldKey === "sub_sub_category" && !subCategoryMatched) {
          obj["sub_sub_category"] = {
            value: "",
            editMode: false,
            errorState: !values[j],
          };
        } else if (
          fieldKey === "sub_sub_category" &&
          obj["sub_category"]?.subSubCategories &&
          obj["sub_category"]?.subSubCategories?.length > 0
        ) {
          for (const item of obj["sub_category"]?.subSubCategories) {
            if (values[j]?.toLowerCase() === item?.name?.toLowerCase()) {
              obj[fieldKey] = {
                subSubCategory: item,
                value: item?.name,
                editMode: false,
                errorState: !values[j],
              };
              subSubCategoryMatched = true;
              allAttributesList.concat(item.sub_sub_category_attributes);
              break;
            }
          }
          if (!subSubCategoryMatched) {
            obj[fieldKey] = {
              value: "",
              editMode: false,
              errorState: !values[j],
            };
          }
        } else if (fieldKey === "is_vat_included") {
          obj[fieldKey] = {
            value: values[j] === true ? "true" : "false",
            editMode: false,
            errorState: !values[j],
          };
        } else if (fieldKey === "title") {
          obj[fieldKey] = {
            value: values[j],
            editMode: false,
            errorState: !values[j],
          };
          productImages.product_name = values[j];
        } else if (fieldKey === "default_image") {
          obj.imgUrlLoaded = false;
          if (values[j]) {
            productImages.url[0] = values[j];
            obj["image_1"] = {
              value: `${process.env.REACT_APP_BASE_FRONTEND_APP_URL}minio/wm-static-images/product-icons/1701945938161-default-product.png`,
              updatedImagesUrl: [
                {
                  url: values[j],
                },
              ],
            };
          } else {
            obj["image_1"] = {
              value: `${process.env.REACT_APP_BASE_FRONTEND_APP_URL}minio/wm-static-images/product-icons/1701945938161-default-product.png`,
              updatedImagesUrl: [
                {
                  url: `${process.env.REACT_APP_BASE_FRONTEND_APP_URL}minio/wm-static-images/product-icons/1701945938161-default-product.png`,
                },
              ],
            };
          }
        } else if (fieldKey.includes("gallery_image")) {
          if (fieldKey === "gallery_image_1") {
            if (obj.imgUrlLoaded !== false) {
              obj.imgUrlLoaded = false;
            }
            if (values[j]) {
              productImages.url[1] = values[j];
              mediaArr.push(values[j]);
            } else {
              productImages.url[1] = null;
            }
          } else if (fieldKey === "gallery_image_2") {
            if (obj.imgUrlLoaded !== false) {
              obj.imgUrlLoaded = false;
            }
            if (values[j]) {
              productImages.url[2] = values[j];
              mediaArr.push(values[j]);
            } else {
              productImages.url[2] = null;
            }
          } else if (fieldKey === "gallery_image_3") {
            if (obj.imgUrlLoaded !== false) {
              obj.imgUrlLoaded = false;
            }
            if (values[j]) {
              productImages.url[3] = values[j];
              mediaArr.push(values[j]);
            } else {
              productImages.url[3] = null;
            }
          } else if (fieldKey === "gallery_image_4") {
            if (obj.imgUrlLoaded !== false) {
              obj.imgUrlLoaded = false;
            }
            if (values[j]) {
              productImages.url[4] = values[j];
              mediaArr.push(values[j]);
            } else {
              productImages.url[4] = null;
            }
          }
        }
        //  else if (fieldKey?.includes("offer")) {
        //   obj[fieldKey] = {
        //     value: Number(values[j]) <= 99 ? values[j] : 0,
        //     editMode: false,
        //     errorState: !values[j],
        //   };
        // }
        else {
          // console.log(obj[fieldKey], 'FIELD KEYYYY FINAL');
          // if(fieldKey === 'ordering_option_label_1'){console.log('REPEATING HERE')};
          obj[fieldKey] = {
            value: values[j],
            editMode: false,
            errorState: !values[j],
          };
        }
        obj["media"] = {
          value: [],
          updatedImagesUrl: [],
        };
        if (
          isAdmin &&
          !supplierId &&
          fieldKey === "master_watermelon_product_code"
        ) {
          obj[fieldKey] = {
            value: "",
            editMode: false,
            errorState: !values[j],
          };
        }
      }
      if (productImages.url.length > 0) {
        imagesDataRef.current.push(productImages);
      }
      // console.log(variantsArr, 'VARIANTS ARRR');
      setVariantsData(variantsArr);
      // console.log(reUploaded, 'reUploaded');

      const currentAttributes = allAttributesList.map((attr: any) =>
        attr.name?.toLowerCase().replace(/\s+/g, "")
      );
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j].toLowerCase().replace(/\s+/g, "");
        if (attributes.includes(key)) {
          if (currentAttributes.includes(key)) {
          } else {
            obj[key] = {
              value: values[j],
              editMode: false,
              disabled: true,
              errorState: !values[j],
            };
          }
        }
      }

      if (reUploaded) {
        // Need to check why we need this duplicate check
        let { uniqueueOptionsProduct, duplicateOptionIndex } =
          removeDuplicateVariants(obj);
        // console.log(newObj, "objj3")

        arrayOfObjects.push({
          ...uniqueueOptionsProduct,
          varaints: variantsArr,
          duplicateProductOptionsIndex: duplicateOptionIndex,
        });
      } else {
        arrayOfObjects.push({ ...obj, varaints: variantsArr });
      }
      // removeDuplicateVariants(variantsArr);
      // console.log(variantsArr, '---variantsArr');
      // console.log(arrayOfObjects, 'ARR FINAL');
    }
    if (imagesDataRef.current.length > 0) {
      fetchProductImages(reUploaded);
    }
    setErrorCounts(getRowsWithErrorsCount(arrayOfObjects));
    return arrayOfObjects;
  }

  //Reupload Toast Message
  const sameFileUpdatedToast = (fileName: string) => {
    setToastData({
      message: `File reuploaded successfully`,
      status: "Success",
    });
    setTimeout(() => {
      setToastData(constants.defaultToastData);
    }, 3000);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let input: any = event.target;
    // setReupload(true);
    const allowedExtensions = ["xlsx", "xls", "csv"];
    if (input?.files && input?.files[0]) {
      const uploadedFile = input.files[0];
      // setReupload(uploadedFile)
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      try {
        if (allowedExtensions?.includes(fileExtension as string)) {
          try {
            const rows: any = await readXlsxFile(uploadedFile, {
              sheet: process.env.REACT_APP_BULK_UPLOAD_SHEET,
            });
            if (rows?.length < 7002) {
              setXlFileName(uploadedFile?.name);
              // console.log(rows, "RRRRRRRRR");
              if (true) {
                let rowsCheck: any = await checkDuplicateProductNameUtils(rows);
                if (rowsCheck?.status) {
                  setFileImportFail(false);
                  dispatch(updateIsLoading(true));
                  rows[0] = rows[0].map(
                    (el: any) =>
                      fileHeaderFields[el.toLowerCase()?.replace(/\s+/g, "")] ||
                      getDynamicMappedKey(
                        el.toLowerCase()?.replace(/\s+/g, "")
                      ) ||
                      el
                  );
                  const arrayOfObjects =
                    categories &&
                    categories.length > 0 &&
                    createArrayOfObjects(rows, true);
                  setTableData(arrayOfObjects);
                  dispatch(updateIsLoading(false));
                  sameFileUpdatedToast(uploadedFile?.name);
                } else {
                  if (rowsCheck?.emptyIndex) {
                    setToastData({
                      message: `Product title is empty at row number ${
                        rowsCheck?.emptyIndex + 1
                      }`,
                      status: "Fail",
                    });
                    setTableData([]);
                    setFileImportFail(true);
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                  }
                  //  else if (rowsCheck?.duplicateIndex) {
                  //   setToastData({
                  //     message: `Product title is duplicate at row number ${
                  //       rowsCheck?.duplicateIndex + 1
                  //     }`,
                  //     status: "Fail",
                  //   });
                  //   setTableData([]);
                  //   setFileImportFail(true);
                  //   setTimeout(() => {
                  //     setToastData(constants.defaultToastData);
                  //   }, 3000);
                  // }
                }
              } else {
                setToastData({
                  message: `Invalid Excel Template`,
                  status: "Fail",
                });
                setTableData([]);
                setFileImportFail(true);
                setTimeout(() => {
                  setToastData(constants.defaultToastData);
                }, 3000);
              }
            } else {
              setToastData({
                message: `Please upload less than 1000 products`,
                status: "Fail",
              });
              setTableData([]);
              setFileImportFail(true);
              setTimeout(() => {
                setToastData(constants.defaultToastData);
              }, 3000);
            }
          } catch (error: any) {
            if (error?.message?.includes('Sheet "Products list" not found')) {
              setToastData({
                message: `Sheet name is not matching, provide a valid sheet with "Product list" as name`,
                status: "Fail",
              });
              setTimeout(() => {
                setToastData(constants.defaultToastData);
              }, 6000);
            } else {
              setToastData({
                message: `Please upload a valid Excel file.`,
                status: "Fail",
              });
              setTableData([]);
              setFileImportFail(true);
              setTimeout(() => {
                setToastData(constants.defaultToastData);
              }, 3000);
            }
          }
        } else {
          setToastData({
            message: `Please upload a valid Excel file.`,
            status: "Fail",
          });
          setTableData([]);
          setFileImportFail(true);
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
        }
      } catch (error) {
        // alert(`Error reading Excel file. Please use the template,${error}`);
        // console.error("Error reading Excel file:", error);
        setToastData({
          message: `Error reading Excel file. Please use the template.`,
          status: "Fail",
        });
        setTableData([]);
        setFileImportFail(true);
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    }
    input.value = "";
  };

  const removeImage = (field: string, rowIndex: number, imageIndex: number) => {
    // console.log(field, rowIndex, imageIndex, '---DELETE');
    const updatedTableData = [...tableData];
    if (field === "image_1") {
      // console.log(updatedTableData, '----updatedTableData');
      updatedTableData[rowIndex].image_1.value = null;
      updatedTableData[rowIndex].image_1.updatedImagesUrl = [];
      setTableData(updatedTableData);
    } else {
      const mediaValue = updatedTableData[rowIndex]?.media?.value || [];
      const newMediaValue = mediaValue.filter(
        (item: any, idx: any) => idx !== imageIndex
      );
      updatedTableData[rowIndex].media.value = newMediaValue;
      updatedTableData[rowIndex].media.updatedImagesUrl = newMediaValue.map(
        (img: any) => {
          return [
            {
              url: img.image,
            },
          ];
        }
      );
      setTableData(updatedTableData);
    }
  };

  useEffect(() => {
    const updatedTableData =
      tableData &&
      tableData.map((rowData: any) => {
        const updatedRowData = { ...rowData };
        for (const property in updatedRowData) {
          const field =
            typeof updatedRowData[property] === "boolean"
              ? {}
              : updatedRowData[property];
          field.errorState = !field.value;
        }
        return updatedRowData;
      });
    setTableData(updatedTableData);
  }, []);

  const checkImageField = () => {
    return (
      tableData?.every((item: any) => item?.image_1?.value?.length) &&
      errorCounts == 0
    );
  };

  useEffect(() => {
    // console.log(errorCounts, 'ERROR COUNT FINAL', tableData);
    checkImageField();
  }, [errorCounts, tableData]);

  // console.log(tableData, categories, "tableData", checkImageField());
  // console.log(variantsData, 'varinatData1111');
  return (
    <div className={styles.parent}>
      {/* <SideNav userType={userType} setUserType={setUserType} selectedId={constants.navLinksId.catalogue}/> */}
      <div className={styles.parentContainer}>
        {/* <div className={styles.searchContainer}>
        <Search placeholder="Search for products, customers, orders, and more!" />
        </div> */}
        <div className={styles.breadcrumb}>
          <BreadCrumb items={breadcrumbItems} />
        </div>

        <div className={styles.editcelltext}>
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
            }}
          >
            <div
              onClick={handleClick}
              ref={popperRef}
              className={styles.textCenter}
            >
              <img src={infoIconBlack} alt="info" />
              Info
            </div>
          </ClickAwayListener>
        </div>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <Paper className={styles.popperPaper}>
            <div className={styles.condition}>
              <span>* Double click to edit the cell</span>
            </div>
            <div className={styles.condition}>
              <span>
                * Uploaded products may take time to appear* in the catalog list
              </span>
            </div>
            <div className={styles.condition}>
              <span>
                * Update all the mandatory fields and default image fields to
                submit the data
              </span>
            </div>
            <div className={styles.condition}>
              <span>
                * All the products may not have the equal number of variants
              </span>
            </div>
            <div className={styles.condition}>
              <span>
                * Attributes which are not part of selected category are
                disabled
              </span>
            </div>
          </Paper>
        </Popper>
        <div className={styles.universalErrorDataContainer}>
          <div className={styles.leftContainer}>
            {errorCounts > 0 || fileImportFail ? (
              <img src={errorXlData} alt="errorXlData" />
            ) : (
              <img src={successXlData} alt="errorXlData" />
            )}
            <div className={styles.detailsContainer}>
              <div className={styles.heading}>{xlFileName}</div>
              {errorCounts > 0 ? (
                <div className={styles.details}>
                  File imported! Fix errors to proceed.
                </div>
              ) : !fileImportFail ? (
                <div className={styles.details}>
                  File was imported successfully.
                </div>
              ) : (
                <div className={styles.details}>File import failed.</div>
              )}
            </div>
          </div>
          <div className={styles.middleContainer}>
            <div className={styles.upContainer}>
              {errorCounts > 0 ? (
                <div className={styles.data}>
                  <span>{errorCounts}</span>
                  <span>/</span>
                  <span>{tableData && tableData.length}</span>
                </div>
              ) : (
                <div className={styles.data}>
                  <span>{tableData && tableData.length}</span>
                </div>
              )}
              <div className={styles.heading}>
                {errorCounts > 0 ? "Total" : ""}
              </div>
            </div>
            <div className={styles.downContainer}>
              {errorCounts > 0 ? "Error Records" : "Total Records"}
            </div>
          </div>
          <div className={styles.rightContainer}>
            <label htmlFor="inputNew">Re upload</label>
            <input
              hidden
              type="file"
              accept=".xls, .xlsx"
              id="inputNew"
              onChange={handleFileUpload}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
          </div>
        </div>
        <div className={styles.shadowParent}>
          <div
            className={`${styles.shadow}
              ${isAdmin ? styles.shadowAdmin : styles.shadowSupplier}
            `}
            // style={{
            //   height: isAdmin ? "562px" : "495px",
            // }}
          ></div>
          <div className={styles.tableContainer}>
            <Table
              variantsData={variantsData}
              removeImage={removeImage}
              tableData={tableData}
              changeEditStatus={changeEditStatus}
              logoUpload={logoUpload}
              categories={categories}
              countriesList={countriesList}
              measuringUnits={measuringUnits}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button label="Cancel" secondary handleClick={cancel} />
          <Button
            label="Submit"
            handleClick={submitData}
            disabled={!checkImageField()}
          />
        </div>
        {toastData?.message && (
          <ToastNotification
            icon={toastData.status == "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={2000}
          />
        )}
      </div>
    </div>
  );
};

export default CreateBulkUpload;
